import React, { useState, useEffect } from "react";
import DiscoverLuxHeader from "../../components/DiscoverLux/Header";
import { appendImage, createImage, createMarkup } from "../../utils";
import axios from "axios";
import { apiRoutes } from "../../constants/apiRoutes";
import Layout from "../../components/Layout";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import routes from "../../components/constants/routes";
import { Link } from "gatsby";
// import { Helmet } from "react-helmet";
import TuncHtml from "trunc-html";
import Helmet from "../../components/Helmet";

const DiscoverLuxAbout = () => {
  const [apiData, setApiData] = useState({});
  const [header, setHeader] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const aboutEvent = apiData?.about_event;
  const aboutEventHistory = apiData?.about_event_history;
  const aboutLux = apiData?.about_luxembourg;
  const pastWinners = apiData?.Results;

  const truncate = (html) => {
    html = TuncHtml(html, 400).html;
    return html;
  };

  const fetchAboutFestival = async () => {
    try {
      const { data } = await axios.get(apiRoutes.discoverluxAbout);
      if (data) {
        setApiData(data.data.attributes);
      }
    } catch (error) {}
  };

  const fetchHeader = async () => {
    try {
      const { data } = await axios.get(apiRoutes.header);
      if (data) {
        setHeader(data.data.attributes);
      }
    } catch (error) {}
  };

  const fetchFilmNews = async () => {
    try {
      const { data } = await axios.get(
        apiRoutes.news(true, 5, "discover luxembourg")
      );
      const newsData = data.data;
      if (newsData.length) setFilteredData(newsData);
    } catch (error) {}
  };

  useEffect(() => {
    fetchHeader();
    fetchAboutFestival();
    fetchFilmNews();
  }, []);

  useEffect(() => {
    appendImage("rich-editor");
  }, [apiData]);

  const seoData = {
    title: "Discover Luxembourg Event | Explore the City's Rich Heritage",
    description:
      "Learn about the Discover Luxembourg event and its rich cultural heritage. Follow challenging routes to explore the city's culture, history, technology, and gastronomy.",
    url: `${process.env.GATSBY_SITE_URL}${routes.DLAbout}/`,
  };

  return (
    <Layout>
      <>
        <Helmet
          title={seoData.title}
          description={seoData.description}
          url={seoData?.url}
        />

        <div className="discover-luxembourg">
          <div className="hero-section">
            {Object.values(header).length ? (
              <img
                src={createImage(
                  header?.discover_luxembourg_image?.data?.attributes?.url
                )}
                alt={header?.discover_luxembourg_image_alt_text}
                width={"auto"}
                height={"auto"}
              />
            ) : null}
          </div>
          <div className="container-fluid p-6 dl-padding-mob">
            <div className="row">
              <div className="col-md-12">
                <div className="head-tab mt-40">
                  <DiscoverLuxHeader />
                  {Object.values(apiData)?.length ? (
                    <div className="tab-content" id="nav-tabContent">
                      <div className="discover-about">
                        <section className="dl-byfoot mt-50">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-12 col-lg-6">
                                <div className="dl-media">
                                  <img
                                    src={createImage(
                                      aboutEvent?.image?.data?.attributes?.url
                                    )}
                                    alt={aboutEvent?.image_alt_text}
                                    width={"auto"}
                                    height={"auto"}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-6 dl-byfoot-col">
                                <div
                                  className="rich-editor dl-details"
                                  dangerouslySetInnerHTML={createMarkup(
                                    aboutEvent?.description
                                  )}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section className="mt-100">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="dl-events">
                                  <Swiper
                                    modules={[
                                      Navigation,
                                      Pagination,
                                      Scrollbar,
                                      A11y,
                                      Autoplay,
                                    ]}
                                    loop={true}
                                    spaceBetween={10}
                                    centeredSlides={true}
                                    slidesPerView={3}
                                    navigation
                                    pagination={{ clickable: true }}
                                    autoplay={{
                                      delay: 5000,
                                      disableOnInteraction: false,
                                    }}
                                  >
                                    {aboutEventHistory?.carousel_image?.data?.map(
                                      (el) => (
                                        <SwiperSlide>
                                          <div className="ff-slider-card">
                                            <img
                                              src={createImage(
                                                el?.attributes?.url
                                              )}
                                              alt={
                                                aboutEventHistory?.carousel_image_alt_text
                                              }
                                              width={"auto"}
                                              height={"auto"}
                                            />
                                          </div>
                                        </SwiperSlide>
                                      )
                                    )}
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                            <div className="row biffl">
                              <div className="col-md-12">
                                <div
                                  className="rich-editor about-biffl"
                                  dangerouslySetInnerHTML={createMarkup(
                                    aboutEventHistory?.description
                                  )}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </section>

                        {filteredData?.length ? (
                          <section className="latest-news mt-100">
                            <h1 className="title text-center mb-50">
                              Latest News
                            </h1>
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12 latest-news-container">
                                  <Swiper
                                    modules={[
                                      Navigation,
                                      Pagination,
                                      Scrollbar,
                                      A11y,
                                      Autoplay,
                                    ]}
                                    spaceBetween={2}
                                    // slidesPerView={3}
                                    navigation
                                    pagination={{ clickable: true }}
                                    autoplay={{
                                      delay: 10000,
                                      disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                      320: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                      768: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 20,
                                      },
                                      1000: {
                                        slidesPerView: 1,
                                      },
                                      1300: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                  >
                                    {filteredData?.map((el, index) => {
                                      const news = el.attributes;
                                      return (
                                        <SwiperSlide>
                                          <div className="latest-news-card row">
                                            <div className="col-md-12 col-lg-6">
                                              <Link
                                                to={`${routes.DLNews}?id=${el.id}`}
                                              >
                                                <img
                                                  src={createImage(
                                                    news?.image?.data
                                                      ?.attributes?.url
                                                  )}
                                                  alt={news?.image_alt_text}
                                                  width={"auto"}
                                                  height={"auto"}
                                                />
                                              </Link>
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                              <div className="content">
                                                <Link
                                                  to={`${routes.DLNews}?id=${el.id}`}
                                                >
                                                  <h2>{news?.title}</h2>
                                                </Link>
                                                <div
                                                  className="rich-editor"
                                                  dangerouslySetInnerHTML={createMarkup(
                                                    truncate(news?.description)
                                                  )}
                                                ></div>

                                                <h3 className="readmore">
                                                  <Link
                                                    to={`${routes.DLNews}?id=${el.id}`}
                                                  >
                                                    {"Read more >>"}
                                                  </Link>
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                          </section>
                        ) : null}

                        {aboutLux?.description ? (
                          <section className="dl-covid19 mt-100">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-12">
                                  <h2 className="">{aboutLux?.title}</h2>
                                  <div
                                    className="rich-editor dl-covid19-container"
                                    dangerouslySetInnerHTML={createMarkup(
                                      aboutLux?.description
                                    )}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </section>
                        ) : null}

                        <section className="upcoming-events mt-80">
                          <h2>{apiData?.event_date_title}</h2>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-12 col-lg-6">
                                <div className="upcoming-events-img">
                                  <img
                                    src={createImage(
                                      apiData?.event_date_image?.data
                                        ?.attributes?.url
                                    )}
                                    alt={apiData?.event_date_image_alt_text}
                                    width={"auto"}
                                    height={"auto"}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-6">
                                <div
                                  className="rich-editor upcoming-events-details"
                                  dangerouslySetInnerHTML={createMarkup(
                                    apiData?.event_date_description
                                  )}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {pastWinners?.length ? (
                          <section className="pastwinners mt-80">
                            <h2>Past Winners:</h2>
                            <div className="container">
                              <div className="row">
                                <div className="col-6 col-md-4">
                                  <div className="pastwinners-year">
                                    <p className="years">Year</p>
                                  </div>
                                </div>
                                <div className="col-6 col-md-8">
                                  <div className="pastwinners-list">
                                    <p className="winners">Winner</p>
                                  </div>
                                </div>
                              </div>
                              {pastWinners?.map((el) => (
                                <div className="row">
                                  <div className="col-6 col-md-4">
                                    <div className="pastwinners-year">
                                      {/* <p className="years">Year</p> */}
                                      <p className="years-details">
                                        {el?.Position}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-8">
                                    <div className="pastwinners-list">
                                      {/* <p className="winners">Winner</p> */}
                                      <p className="winner-details">
                                        {el?.Flim}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </section>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <p className="text-center" style={{ margin: "15em 0" }}></p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default DiscoverLuxAbout;
