import { navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import routes from "../constants/routes";

const DiscoverLuxHeader = () => {
  const [dlscroll, setdlScroll] = useState(false);
  const [path, setPath] = useState(undefined);
  const setTabStatus = (route) => {
    navigate(route);
  };
  useEffect(() => {
    const tempPath =
      typeof window !== undefined ? window.location.href : undefined;
    setPath(tempPath);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setdlScroll(window.scrollY > 341);
    });
  }, []);

  return (
    <nav className={dlscroll ? "nav-header dlstickyheader" : "nav-header"}>
      <div className="nav nav-tabs tab-mob-hide" id="nav-tab" role="tablist">
        <button
          onClick={() => setTabStatus(routes.DLAbout)}
          className={`nav-link ${
            path?.includes(routes.DLAbout) ? "active" : ""
          }`}
          id="nav-about-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-about"
          type="button"
          role="tab"
          aria-controls="nav-about"
          aria-selected="true"
        >
          About
        </button>

        <button
          onClick={() => setTabStatus(routes.DLCurrentEvent)}
          className={`nav-link ${
            path?.includes(routes.DLCurrentEvent) ? "active" : ""
          }`}
          id="nav-currentevent-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-currentevent"
          type="button"
          role="tab"
          aria-controls="nav-currentevent"
          aria-selected="false"
        >
          Current Event
        </button>
        <button
          onClick={() => setTabStatus(routes.DLPastEvents)}
          className={`nav-link ${
            path?.includes(routes.DLPastEvents) ? "active" : ""
          }`}
          id="nav-pastevent-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-pastevent"
          type="button"
          role="tab"
          aria-controls="nav-pastevent"
          aria-selected="false"
        >
          Past Events
        </button>
        <button
          onClick={() => setTabStatus(routes.DLNews)}
          className={`nav-link ${
            path?.includes(routes.DLNews) ? "active" : ""
          }`}
          id="nav-news-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-news"
          type="button"
          role="tab"
          aria-controls="nav-news"
          aria-selected="false"
        >
          News
        </button>
        <button
          onClick={() => setTabStatus(routes.DLRegister)}
          className={`nav-link ${
            path?.includes(routes.DLRegister) ? "active" : ""
          }`}
          id="nav-register-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-register"
          type="button"
          role="tab"
          aria-controls="nav-register"
          aria-selected="false"
        >
          Register
        </button>
      </div>
    </nav>
  );
};

export default DiscoverLuxHeader;
